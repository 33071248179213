<template>
	<div class="labels-outer">
		<div v-for="(checkbox, index) in values" :key="index">
			<HokCheckbox
				:id="`jobAlarm-${id}-${msgIndex}-${index}`"
				v-model="checkbox.value"
				:name="`${name}[]`"
				:checked="currentVals.indexOf(checkbox.label) !== -1"
				@change="toggleValue(checkbox.value)"
			>
				{{ checkbox.label }}
			</HokCheckbox>
		</div>
	</div>
</template>

<script lang="ts">
import type { APILowerCaseISOCountryCode } from '@hokify/common';
import HokCheckbox from '@hokify/shared-components-nuxt3/lib/components/HokCheckbox.vue';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { useUserRootStore } from '@/stores/root';
import { useUserJobAlarmStore } from '@/stores/user-job-alarm';

export default defineComponent({
	name: 'InterviewAnswerJobAlarm',
	components: { HokCheckbox },
	emits: ['jobAlarmDone'],
	data() {
		const currentVals: string[] = [];

		return {
			currentVals
		};
	},
	computed: {
		...mapStores(useUserJobAlarmStore, useUserRootStore)
	},
	methods: {
		toggleValue(value) {
			if (this.currentVals.includes(value)) {
				this.currentVals.splice(this.currentVals.indexOf(value), 1);
			} else {
				this.currentVals.push(value);
			}
		},
		async handleSubmit(): Promise<boolean> {
			if (this.currentVals.length) {
				this.$trackUserEvent?.('activate_jobalarm_completed', { pageElement: 'interview' });
				try {
					await this.userJobAlarmStore.setJobAlarm({
						location: { address: this.location },
						region: (this.userRootStore.topLevelDomain as APILowerCaseISOCountryCode) || '',
						search: { fieldIds: this.currentVals }
					});
				} catch (err: any) {
					console.error('setJobalarm failed', err);
					this.$nuxt.$errorHandler(
						err,
						'Beim setzen deines Jobalarms ist ein Fehler aufgetreten. Bitte versuch es später noch einmal.'
					);
				}
			} else {
				this.$trackUserEvent?.('dismiss_jobalarm', { pageElement: 'interview' });
			}

			this.$emit('jobAlarmDone');

			return true;
		}
	},
	props: {
		id: { type: String, required: true },
		msgIndex: { type: Number, required: true },
		name: { type: String, default: '' },
		location: { type: String, default: '' },
		values: { type: Array as PropType<{ label: string; value: string }[]>, default: () => [] }
	}
});
</script>
