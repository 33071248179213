<template>
	<div
		data-cy="create-cv"
		class="flex items-center space-x-6 cursor-pointer border-2 border-color-grey-lightest rounded-lg max-w-sm py-4 px-3 mt-4"
		@click="callFct(option.fct, false, option)"
	>
		<NuxtImg src="/cv-create.png" alt="Lebenslauf erstellen" class="aspect-square" />
		<div>
			<p class="mb-3 text-color-main font-bold">Profil manuell erstellen</p>
			<p class="text-color-grey-medium text-sm mb-0">
				Lege deinen Lebenslauf einfach und in wenigen Schritten an.
			</p>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import type { IInterviewButtonAnswerOption } from '@hokify/common';

export default defineComponent({
	name: 'InterviewAnswerCvButton',
	props: {
		option: { type: Object as PropType<IInterviewButtonAnswerOption>, required: true },
		callFct: { type: Function, required: true }
	}
});
</script>
