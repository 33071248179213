<template>
	<HokModal
		name="preventClosing"
		:width="$isMobile.any ? '95%' : '500px'"
		:show-close-button="false"
	>
		<h4>Bewerbung abbrechen?</h4>
		<p class="mb-3">
			{{
				loggedIn
					? 'Deine Bewerbung wird gespeichert und du kannst sie später abschließen.'
					: 'Deine Änderungen werden nicht gespeichert und du kannst jederzeit neu anfangen. '
			}}
		</p>
		<HokButton
			fullwidth="always"
			outline
			color="main"
			class="mb-3"
			@click="$emit('cancel-application')"
			>Zur Jobübersicht</HokButton
		>
		<HokButton fullwidth="always" class="mb-4" color="main" @click="$emit('continue-application')"
			>Bewerbung fortsetzen</HokButton
		>
	</HokModal>
</template>

<script lang="ts">
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import { useLoginStore } from '@/stores/login';

export default defineComponent({
	name: 'PreventClosingModal',
	components: {},
	emits: ['cancel-application', 'continue-application'],
	computed: {
		loggedIn() {
			return this.loginStore.loggedIn;
		},
		...mapStores(useLoginStore)
	}
});
</script>
