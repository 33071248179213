<template>
	<client-only>
		<div id="main" :class="mainClasses" class="flex flex-col h-full">
			<!-- INTERVIEW -->
			<template v-if="interviewStarted && !interviewFinished">
				<!-- do not use tabs for mobile version -->
				<ApplyTypeForm
					v-if="hideTabs && $isMobile.any"
					ref="typeform"
					:match-obj="matchObj"
					:first-msg="firstMsg"
					:answered-msgs="answeredMsgs"
					:question-stats="questionStats"
					:guest-login="guestLogin"
					:audience-id="audienceId"
					:allow-cv-parsing="allowCvParsing"
					:job-nr-or-id="jobNrOrId"
					:interview-mode="interviewMode"
					@update-allow-cv-parsing="$emit('update-allow-cv-parsing', $event)"
					@update-match-obj="$emit('update-match-obj', $event)"
					@interview-done="$emit('interview-done')"
				>
					<template #heading>
						<slot name="heading" />
					</template>
				</ApplyTypeForm>
				<!-- always use tabs for desktop or if hideTabs wasn't set -->
				<Tabs
					v-else
					:align-center="true"
					name="applying"
					:hide-tabs="hideTabs"
					@changed="selectTab"
				>
					<Tab id="apply" :scroll-to-top="false" name="Bewerbung" class="flex flex-col flex-1">
						<ApplyTypeForm
							ref="typeform"
							:match-obj="matchObj"
							:first-msg="firstMsg"
							:answered-msgs="answeredMsgs"
							:question-stats="questionStats"
							:guest-login="guestLogin"
							:audience-id="audienceId"
							:allow-cv-parsing="allowCvParsing"
							:job-nr-or-id="jobNrOrId"
							:interview-mode="interviewMode"
							@update-allow-cv-parsing="$emit('update-allow-cv-parsing', $event)"
							@update-match-obj="$emit('update-match-obj', $event)"
							@interview-done="$emit('interview-done')"
						>
							<template #heading>
								<slot name="heading" />
							</template>
						</ApplyTypeForm>
					</Tab>
					<Tab id="job" name="Ausschreibung">
						<slot name="job-detail" />
					</Tab>
				</Tabs>
			</template>

			<!-- MATCH OVERVIEW -->
			<MatchOverview
				v-else-if="matchObj && (interviewFinished || alreadyApplied)"
				:match="matchObj"
				:already-applied="alreadyApplied"
				:audience-id="audienceId"
				:interview-mode="interviewMode"
				:view-only="false"
				@restart-interview="$emit('restart-interview')"
			/>

			<!-- ERROR -->
			<div v-else-if="err">
				<div class="container text-center mt-10">
					<h3 v-if="getErrorCode(err) === 'JOB_OFFLINE'">
						Dieser Job ist leider nicht mehr verfügbar.
					</h3>
					<h3 v-else>
						{{ 'Something went wrong' }}
					</h3>
					<div>
						<HokButton fullwidth="mobile" color="main" class="mb-4" to="/pwa">
							Weitere Jobs entdecken
						</HokButton>
					</div>
				</div>
			</div>

			<!-- PreventClosingModal -->
			<PreventClosingModal
				@continue-application="continueApplication"
				@cancel-application="cancelApplication"
			/>
		</div>
	</client-only>
</template>

<script lang="ts">
import type { ApplyMode, IPWAInterviewQuestion } from '@hokify/pwa-core-nuxt3/helpers/apply';
import type {
	IAPILoginUser,
	IAPIMatchForJobSeeker,
	IAPIJobFilter,
	APITypeObjectId,
	APIObjectType
} from '@hokify/common';
import Tabs from '@hokify/shared-components-nuxt3/lib/components/Tabs.vue';
import Tab from '@hokify/shared-components-nuxt3/lib/components/Tab.vue';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { mapStores } from 'pinia';
import PreventClosingModal from '@/components/user/application/PreventClosingModal.vue';
import ApplyTypeForm from '@/components/user/application/ApplyTypeForm.vue';
import MatchOverview from '@/components/user/match/MatchOverview.vue';
import { useUiStateStore } from '@/stores/uistate';
import { useUserJobFilterStore } from '@/stores/user-job-filter';
import { useUserProfileStore } from '@/stores/user-profile';
import { useLoginStore } from '@/stores/login';

function hasFocusCurrent(obj: any): obj is { focusCurrent(): void } {
	return typeof obj.focusCurrent === 'function';
}

export default defineComponent({
	name: 'ApplyBaseTemplate',
	layout: 'apply',
	components: {
		PreventClosingModal,
		Tabs,
		Tab,
		ApplyTypeForm,
		MatchOverview
	},
	emits: ['update-allow-cv-parsing', 'update-match-obj', 'interview-done', 'restart-interview'],
	data() {
		return {
			EventBus
		};
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		loggedIn() {
			return this.loginStore.loggedIn;
		},
		mainClasses() {
			const classes: string[] = [];
			if (!this.interviewFinished) {
				if (this.interviewFinished) {
					classes.push('match-overview');
				} else {
					classes.push('typeform');
				}
			} else {
				classes.push('finished');
			}
			if (this.$isMobile) {
				if (this.$isMobile.any) {
					classes.push('mobile');
				}
				if (this.$isMobile.apple && this.$isMobile.apple.device) {
					classes.push('apple');
				}
				if (this.$isMobile.android && this.$isMobile.android.device) {
					classes.push('android');
				}
			}
			return classes;
		},
		getJobFilter(): IAPIJobFilter | undefined {
			return this.userProfileStore?.obj?.jobFilter;
		},
		...mapStores(useUiStateStore, useUserJobFilterStore, useUserProfileStore, useLoginStore)
	},
	beforeMount() {
		this.uiStateStore.setDoNotDisturbMode(true);
	},
	methods: {
		getErrorCode(err: any): string | undefined {
			if (this.$nuxt.$isHokFetchResponseError(err)) {
				return err.response.data.code;
			}

			if ('data' in err && 'code' in err.data) {
				return err.data.code;
			}

			if (typeof err === 'object' && err !== null) {
				return err.code;
			}

			if (typeof err === 'string') {
				return err;
			}

			return undefined;
		},
		selectTab({ tab }) {
			if (tab.id === 'apply' && this.$refs?.typeform && hasFocusCurrent(this.$refs.typeform)) {
				this.$refs.typeform.focusCurrent();
			}

			if (tab.id === 'company-scroll-container') {
				// make sure to update carousel when switching to a company page, otherwise width calculation would be wrong
				this.EventBus.$emit('page-close-observed');
				// make sure to load company details
				this.EventBus.$emit('load-company-details');
			}
		},
		preventClosing() {
			this.$trackUserEvent?.('application_cancel_show_modal', {});
			this.$modal.show('preventClosing');
		},
		continueApplication() {
			this.$trackUserEvent?.('application_cancel_proceed_application', {});
			this.$modal.hide('preventClosing');
		},
		async cancelApplication() {
			await this.$trackUserEvent?.('application_cancel_leave_application', {});
			this.$modal.hide('preventClosing');

			if (this.loggedIn && this.user) {
				const hasJobFilter =
					!!this.getJobFilter?.fieldIds?.length || !!this.getJobFilter?.searchterm;
				if (!hasJobFilter && this.matchObj) {
					await this.userJobFilterStore.setFilter({
						search: {
							fieldIds: (this.matchObj.relation.obj as any).fields.map(field => field._id)
						},
						location: { address: (this.matchObj.relation.obj as any).location.name }
					});
				}
				this.$router.push('/pwa');
			} else if (window.history.length > 2) {
				window.history.back();
				return false;
			}
		},
		beforeUnmount() {
			this.uiStateStore.setDoNotDisturbMode(false);
			if (!this.interviewFinished && this.user) {
				this.$trackUserEvent?.('cancel_application', {
					item_id: this.matchObj?.relation?.obj?._id,
					item_type: this.matchObj?.relation?.type
				});
			}
		}
	},
	props: {
		interviewMode: { type: String as PropType<ApplyMode>, required: true },
		interviewStarted: { type: Boolean, default: false },
		hideTabs: { type: Boolean, default: false },
		interviewFinished: { type: Boolean, default: false },
		guestLogin: { type: Boolean, default: false },
		alreadyApplied: { type: Boolean, default: false },
		matchObj: { type: Object as PropType<IAPIMatchForJobSeeker | null>, default: null },
		answeredMsgs: { type: Array as PropType<IPWAInterviewQuestion[]>, default: null },
		firstMsg: {
			type: [Object, Boolean, undefined] as PropType<IPWAInterviewQuestion>,
			default: () => {}
		},
		questionStats: { type: Object, default: () => {} },
		err: { type: [Object, Error, null], default: null },
		restartedInterview: { type: Boolean, default: false },
		audienceId: {
			type: String as PropType<APITypeObjectId<APIObjectType.CompanyAudience>>,
			required: false,
			default: () => ''
		},
		allowCvParsing: { type: Boolean, required: true },
		jobNrOrId: { type: [String, Number], required: true, default: '' }
	}
});
</script>
