import { CodeError } from './CodeError';

export class NoAnswerError extends CodeError {
	public constructor(
		msg: string,
		public additionalInfo?: any
	) {
		super('NO_ANSWER', msg);
		// Set the prototype explicitly. https://github.com/Microsoft/TypeScript/issues/13965
		Object.setPrototypeOf(this, NoAnswerError.prototype);
	}
}
