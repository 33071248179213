<template>
	<div>
		<input
			v-if="option && option.inputType === 'multiline'"
			type="hidden"
			:v-model="internalValue"
			:name="option && option.name"
		/>
		<!-- special case for motivation question / open questions -->
		<!--	only show inside typeform, do not show in match-overview! -->
		<div
			v-if="viewState && viewState.mode === 'typeform'"
			class="flex"
			:class="isMotivationType ? 'justify-between' : 'justify-end'"
		>
			<p
				v-if="isMotivationType"
				class="mb-0 w-1/2 bg-color-grey-lightest rounded-md px-2 py-1 text-sm inline-block"
			>
				An: {{ contactPersonOrCompanyName }}
			</p>
			<!-- this needs to be v-show, otherwise server/client would be different due to localStorageAvailable is client only-->
			<p
				v-show="option && option.inputType === 'multiline' && localStorageAvailable"
				class="mb-0 w-1/2 self-end text-right text-sm mr-1"
			>
				<span v-show="isTyping"> Wird gespeichert... </span>
				<span v-show="!isTyping && internalValue && internalValue.trim().length">
					Gespeichert
				</span>
			</p>
		</div>
		<template v-if="option && option.inputType === 'multiline'">
			<HokTextArea
				:id="`text-${id}-${msgIndex}`"
				ref="textAreaInput"
				v-model="internalValue"
				wysiwyg
				:name="option && option.name"
				:disabled="disabled"
				:max-length="option.maxLength || 300"
				:min-length="option.minLength || 5"
				@focus="keyboardHandler('focus', $event)"
				@blur="keyboardHandler('blur', $event)"
			>
				{{ option.placeholder || 'Nachricht eingeben...' }}
			</HokTextArea>

			<!-- special case for motivation question -->
			<div
				:class="[
					{ 'flex justify-between': isMatchOverview && isMotivationType && !$isMobile.any },
					{ 'flex justify-end': isMatchOverview && !$isMobile.any }
				]"
			>
				<HokButton
					v-if="isMotivationType"
					outline
					color="main"
					:disabled="hasAlreadyClicked"
					:fullwidth="isMatchOverview && $isMobile.any ? 'mobile' : false"
					class="mt-4 sm:mr-1 iphone5"
					@click="generateRandomSuggestion"
					>Vorschlag anzeigen
				</HokButton>
				<HokButton
					v-if="isMatchOverview"
					fullwidth="mobile"
					class="mt-4 sm:mr-1 iphone5"
					color="main"
					submit
					>Speichern
				</HokButton>
			</div>
		</template>
		<EmailOrPhone
			v-else-if="isEmailOrPhone || isPhone"
			:id="id"
			v-model="internalValue"
			:phone-only="isPhone"
			:browser-autocomplete="false"
			:styling="viewState && viewState.mode"
			:name="option && option.name"
			@focus="keyboardHandler('focus', $event)"
			@blur="keyboardHandler('blur', $event)"
			@click="$emit('click', internalValue)"
			@change="$emit('input', internalValue)"
		>
			{{ (option && option.placeholder) || 'Nachricht eingeben...' }}
		</EmailOrPhone>
		<HokInput
			v-else
			:id="`text-${id}-${msgIndex}`"
			ref="input"
			v-model="internalValue"
			:styling="viewState && viewState.mode"
			:browser-autocomplete="false"
			:type="(option && option.inputType) || 'text'"
			:name="option && option.name"
			:disabled="disabled"
			@focus="keyboardHandler('focus', $event)"
			@blur="keyboardHandler('blur', $event)"
		>
			{{ (option && option.placeholder) || 'Nachricht eingeben...' }}
		</HokInput>
		<p v-if="questionInfo" class="text-xs" v-html="questionInfo" />
		<div v-if="showPrivacyCheckbox" class="text-xs -mb-6">
			<HokCheckbox
				:id="`privacy_user_register-${id}-${msgIndex}`"
				v-model="userPrivacy"
				:checked="false"
				required
				name="privacy_user_register"
			>
				Um die Bewerbung über die mobile Job-Plattform hokify abwickeln zu können, darf hokify meine
				Daten speichern.
				<template #agb>
					Mit dem Fortfahren werden die
					<HokLink
						:to="agbLink"
						target="_blank"
						class="underline"
						link-type="external"
						data-ga_action="agb-lesen"
						data-ga_label="top-page"
						>AGB</HokLink
					>
					akzeptiert.
				</template>
				<template #more>
					<HokLink to="/privacy#user-register" target="_blank" class="text-xs block underline">
						Datenschutzerklärung & Widerrufsrecht
					</HokLink>
				</template>
			</HokCheckbox>
		</div>
	</div>
</template>

<script lang="ts">
import HokInput from '@hokify/shared-components-nuxt3/lib/components/HokInput.vue';
import EmailOrPhone from '@hokify/shared-components-nuxt3/lib/components/EmailOrPhone.vue';
import HokTextArea from '@hokify/shared-components-nuxt3/lib/components/HokTextArea.vue';
import HokCheckbox from '@hokify/shared-components-nuxt3/lib/components/HokCheckbox.vue';
import type { IAPILoginUser, IAPIOwnerSnippet, IInterviewTextAnswerOption } from '@hokify/common';
import { externalLinkHelper } from '@hokify/shared-components-nuxt3/lib/helpers/externalLink';
import { lsTest } from '@hokify/shared-components-nuxt3/lib/helpers/localstorage';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { mapStores } from 'pinia';
import { useUserProfileStore } from '@/stores/user-profile';
import { useUserRootStore } from '@/stores/root';
import motivationSuggestions from './motivationSuggestions.json';

export default defineComponent({
	name: 'InterviewAnswerText',
	components: {
		HokCheckbox,
		HokInput,
		HokTextArea,
		EmailOrPhone
	},
	emits: ['input', 'click', 'update:modelValue'],
	data() {
		const hasAlreadyClicked = false;
		const timeOut = undefined as any;
		const isTyping = false;
		const userPrivacy = false;
		const { env } = useRuntimeConfig().public;

		return {
			env,
			motivationSuggestions,
			hasAlreadyClicked,
			isTyping,
			localStorageAvailable: lsTest(),
			timeOut,
			userPrivacy
		};
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		isMotivationType() {
			return this.moduleOptions?.type === 'motivation';
		},
		isEmailOrPhone() {
			return this.moduleOptions?.type === 'emailOrPhone';
		},
		isPhone() {
			return this.option?.inputType === 'tel';
		},
		contactPersonOrCompanyName(): string {
			if (this.contactPerson?.displayName) {
				return this.contactPerson.displayName;
			}
			return this.companyName;
		},
		agbLink() {
			return externalLinkHelper(
				this.env,
				'/disclaimer',
				'_blank',
				this.userRootStore.topLevelDomain || 'at'
			);
		},
		contactPersonText(): string | undefined {
			if (!this.contactPerson?.general.lastName || !this.contactPerson?.general?.gender) {
				return undefined;
			}

			switch (this.contactPerson.general.gender) {
				case 'female':
					return `Sehr geehrte Frau ${this.contactPerson?.displayName}!`;
				case 'male':
					return `Sehr geehrter Herr ${this.contactPerson?.displayName}!`;
				default:
					return undefined;
			}
		},
		textAreaInput() {
			return this.$refs.textAreaInput as any;
		},
		internalValue: {
			get() {
				return this.modelValue;
			},
			set(value) {
				if (!value?.trim()) {
					this.hasAlreadyClicked = false;
				}

				// if multiline & localStorageAvailable save user input to LS and show "saved" info in UI
				if (this.localStorageAvailable && this.option?.inputType === 'multiline') {
					if (this.timeOut) {
						clearTimeout(this.timeOut);
					}
					this.isTyping = true;

					this.timeOut = setTimeout(() => {
						this.isTyping = false;
						// Identifier + MatchID + QuestionID
						localStorage.setItem(`saved-${this.matchObjId}-${this.id}`, JSON.stringify(value));
					}, 1500);
				}
				this.$emit('update:modelValue', value);
			}
		},
		...mapStores(useUserProfileStore, useUserRootStore)
	},
	async mounted() {
		// for multiline, set text if there is already one in localStorage
		if (this.localStorageAvailable && this.option?.inputType === 'multiline') {
			const text = localStorage.getItem(`saved-${this.matchObjId}-${this.id}`) || '';
			if (text) {
				this.internalValue = JSON.parse(text);
			}
		}
	},
	methods: {
		populateAnswer(event) {
			// disable eslint rule cause I really want the plain text!
			// eslint-disable-next-line
			this.internalValue = event.target.innerText;
		},
		generateRandomSuggestion() {
			const prefix = this.contactPersonText
				? `${this.contactPersonText.trim()}\r\n\r\n`
				: 'Sehr geehrte Damen und Herren!\r\n\r\n';
			const appendix = `\r\n\r\nMit freundlichen Grüßen,\r\n${
				this.userProfileStore.obj?.displayName
					? this.userProfileStore.obj.displayName.trim()
					: 'Vorname Nachname'
			}`;

			const usedNumbers: number[] = [];
			// eslint-disable-next-line no-constant-condition
			while (true) {
				let random = Math.floor(Math.random() * (motivationSuggestions.length - 1));
				const text = motivationSuggestions[random];
				// make sure we have no duplicate suggestions
				while (usedNumbers.includes(random)) {
					// make sure to not get an IndexOutOfBounds error
					random = (random + 1) % this.motivationSuggestions.length;
				}
				usedNumbers.push(random);
				if (prefix.length + text.length + appendix.length < (this.option?.maxLength || 500)) {
					this.textAreaInput.setContent(prefix + text + appendix);
					this.hasAlreadyClicked = true;
					break;
				}
				// if we have tried all given suggestions and all are too long, set default text
				if (usedNumbers.length >= motivationSuggestions.length) {
					this.textAreaInput.setContent(
						`${prefix}Ich bin davon überzeugt, dass ich sehr gut zu Ihrer Ausschreibung passe und möchte Sie gerne persönlich davon überzeugen.${appendix}`
					);
					this.hasAlreadyClicked = true;
					break;
				}
			}
		},
		async handleSubmit(): Promise<boolean> {
			await this.submitForm(undefined);
			return true;
		},
		beforeUnmount() {
			// make sure saving into localStorage has finished before potentially deleting it again
			if (this.timeOut) {
				clearTimeout(this.timeOut);
			}
			if (this.localStorageAvailable && this.option?.inputType === 'multiline') {
				localStorage.removeItem(`saved-${this.matchObjId}-${this.id}`);
			}
		}
	},
	props: {
		id: { type: String, required: true },
		matchObjId: { type: String, default: () => '' },
		companyName: { type: String, default: '' },
		contactPerson: {
			type: Object as PropType<IAPIOwnerSnippet>,
			default: () => {},
			required: false
		},
		msgIndex: { type: Number, required: true },
		questionInfo: { type: String, default: () => '' },
		option: { type: Object as PropType<IInterviewTextAnswerOption>, default: () => {} },
		disabled: { type: Boolean },
		showPrivacyCheckbox: { type: Boolean },
		submitForm: {
			type: Function as PropType<
				(e?: Event, nameOrPayload?: string | Record<string, string>, value?: any) => unknown
			>,
			default: () => {}
		},
		moduleOptions: { type: Object, default: () => {} },
		keyboardHandler: { type: Function, default: () => ({}) },
		modelValue: { type: String, default: '' },
		errorOnRequest: { type: [Object, Boolean], default: false },
		isMatchOverview: { type: Boolean, default: false },
		viewState: {
			type: Object as PropType<{ mode: 'typeform' | 'match-overview' }>,
			default: () => {}
		}
	}
});
</script>
<style lang="scss" scoped>
@media (max-width: 374px) {
	.iphone5 {
		float: right;
	}
}
</style>
