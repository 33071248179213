<template>
	<div>
		<div v-for="(answerOption, index) in answerOptions" :key="answerOption.name">
			<HokInput
				:id="`text-${id}-${msgIndex}-${index}`"
				v-model="internalModuleOptions[answerOption.name]"
				:browser-autocomplete="false"
				:type="answerOption.type || 'text'"
				:name="answerOption.name"
				:disabled="disabled"
				:required="required"
				@input="$emit('input', $event)"
				@focus="keyboardHandler('focus', $event)"
				@blur="keyboardHandler('blur', $event)"
			>
				{{ answerOption.placeholder || 'Nachricht eingeben...' }}
			</HokInput>
			<template v-if="answerOption && answerOption.name === 'password'">
				<HokButton
					outline
					color="main"
					is-text
					font-weight="light"
					class="text-sm text-left mb-9"
					@click="resetPassword"
				>
					Passwort vergessen?
				</HokButton>
				<div>
					<HokButton class="mb-8" color="main" fullwidth="mobile" submit data-cy="interview-login">
						anmelden
					</HokButton>
				</div>
			</template>
		</div>
		<div v-if="showPrivacyCheckbox && !isMatchOverview" class="text-xs -mb-6">
			<HokCheckbox
				:id="`privacy_user_register-${id}-${msgIndex}`"
				v-model="userPrivacy"
				:checked="false"
				required
				name="privacy_user_register"
			>
				Um die Bewerbung über die mobile Job-Plattform hokify abwickeln zu können, darf hokify meine
				Daten speichern.
				<template #agb>
					Mit dem Fortfahren werden die
					<HokLink
						:to="agbLink"
						target="_blank"
						class="underline"
						link-type="external"
						data-ga_action="agb-lesen"
						data-ga_label="top-page"
						>AGB</HokLink
					>
					akzeptiert.
				</template>
				<template #more>
					<HokLink to="/privacy#user-register" target="_blank" class="text-xs block underline">
						Datenschutzerklärung & Widerrufsrecht
					</HokLink>
				</template>
			</HokCheckbox>
		</div>

		<div class="flex justify-end">
			<HokButton v-if="isMatchOverview" submit :disabled="disabled" color="main" fullwidth="mobile">
				Speichern
			</HokButton>
		</div>
	</div>
</template>

<script lang="ts">
import HokInput from '@hokify/shared-components-nuxt3/lib/components/HokInput.vue';
import HokCheckbox from '@hokify/shared-components-nuxt3/lib/components/HokCheckbox.vue';
import { defineComponent, markRaw, defineAsyncComponent } from 'vue';
import { externalLinkHelper } from '@hokify/shared-components-nuxt3/lib/helpers/externalLink';
import { mapStores } from 'pinia';
import { insidePages } from '@hokify/shared-components-nuxt3/lib/plugins/insidepage/insidePageService';
import { useUserRootStore } from '@/stores/root';
import { useUserProfileStore } from '@/stores/user-profile';
import { useLoginStore } from '@/stores/login';

export default defineComponent({
	name: 'InterviewAnswerGrouped',
	components: {
		HokInput,
		HokCheckbox
	},
	emits: ['input'],
	data() {
		const userPrivacy = false;
		return {
			userPrivacy,
			insidePages
		};
	},
	computed: {
		internalModuleOptions() {
			return this.moduleOptions;
		},
		isInsidePageOpen() {
			return this.insidePages.pages.length > 0;
		},
		agbLink() {
			return externalLinkHelper(
				this.env,
				'/disclaimer',
				'_blank',
				this.userRootStore.topLevelDomain || 'at'
			);
		},
		...mapStores(useUserProfileStore, useUserRootStore, useLoginStore)
	},
	methods: {
		resetPassword() {
			if (this.internalModuleOptions.email) {
				this.$loginStore.setLoginIdentifier(this.internalModuleOptions.email);
			}

			if (this.isInsidePageOpen) {
				const passwordPage = markRaw(
					defineAsyncComponent(
						() => import('@hokify/login-stack-nuxt3/lib/components/login/password/PasswordBase.vue')
					)
				);
				try {
					this.$page.push(
						passwordPage,
						{ forgotPwd: true, loadingDone: true, isInsidePage: true },
						{
							mode: 'modal',
							name: 'passwordPage',
							on: {
								done: () => {
									this.$page.goBack();
								}
							}
						}
					);
				} catch (err) {
					this.$nuxt.$errorHandler(err);
				}
			} else {
				this.$router.push('/pwa/password?forgotPwd=true');
			}
		}
	},
	props: {
		id: { type: String, required: true },
		msgIndex: { type: Number, required: true },
		answerOptions: { type: Array, default: () => [] },
		disabled: { type: Boolean },
		moduleOptions: {
			type: Object,
			default: () => {}
		},
		keyboardHandler: { type: Function, default: () => ({}) },
		required: { type: Boolean, default: false },
		isMatchOverview: { type: Boolean, default: false },
		showPrivacyCheckbox: { type: Boolean }
	}
});
</script>
