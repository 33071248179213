import {
	// TODO @pkerschbaum uncomment as soon as common types enum exports are fixed
	// https://app.asana.com/0/1207203365740581/1208291918062227/f
	// https://github.com/hokify/hokify/pull/9557
	// InterviewCategory,
	// InterviewType,
	type IInterviewQuestion,
	type InterviewAnswerOptions,
	type IInterviewAnswerOption,
	type IAPIUserCvExperience,
	type IAPIUserCvEducation
} from '@hokify/common';

export enum ApplyMode {
	Apply = 'apply',
	ApplyCompany = 'apply-company'
}

export enum InterviewCategory {
	Login = 1,
	PersonalBeforeCV = 2,
	RealCV = 3,
	Personal = 4,
	JobSpecific = 5,
	CVTypeQuestion = 6,
	Documents = 7,
	Overview = 8
}

export enum InterviewType {
	PROFILE = 'profile',
	ATTACHMENT = 'attachment',
	QUESTION = 'question',
	INFORMATION = 'information'
}

export interface IPWAInterviewLinkAnswerOption extends IInterviewAnswerOption {
	type: 'link';
	label: string;
	cb: () => Promise<void>;
}

export interface IPWASocialLoginAnswerOption extends IInterviewAnswerOption {
	type: 'socialLogin';
}

export interface IPWAJobAlarmAnswerOption extends IInterviewAnswerOption {
	type: 'jobAlarm';
}

export interface IPWAStartWithNameAnswerOption extends IInterviewAnswerOption {
	type: 'grouped-answer';
	answerOptions: { type?: string; name: string; placeholder: string }[];
}

export type IPWAAnswerOptions =
	| InterviewAnswerOptions
	| IPWAInterviewLinkAnswerOption
	| IPWAStartWithNameAnswerOption
	| IPWASocialLoginAnswerOption
	| IPWAJobAlarmAnswerOption;

export interface IPWAInterviewQuestion
	extends Omit<IInterviewQuestion, 'answerOptions' | 'showForOverview'> {
	editable?: boolean;
	questionInfo?: string;
	lastError?: string;
	action?: any /* custom local client action */;
	required?: boolean;
	showPrivacyCheckbox?: boolean;
	recaptcha?: boolean;
	answerOptions?: IPWAAnswerOptions[];
	showForOverview?: boolean;
	parsedExperiences?: IAPIUserCvExperience[];
	parsedEducations?: IAPIUserCvEducation[];
}

const HokifyApply = {
	cancelLogin: (): IPWAInterviewQuestion => ({
		question: 'Login abgebrochen. Wie lautet deine E-Mail-Adresse oder Telefonnummer?',
		identifier: 'cancelLogin',
		baseIdentifier: 'cancelLogin',
		type: InterviewType.QUESTION,
		module: 'local-cancel-login',
		moduleOptions: { type: 'emailOrPhone' },
		editable: false,
		showPrivacyCheckbox: true,
		category: InterviewCategory.Login,
		answerOptions: [
			{
				type: 'text',
				name: 'email',
				inputType: false,
				placeholder: 'E-Mail-Adresse / Telefonnummer'
			},
			{
				type: 'socialLogin'
			}
		]
	}),
	startLogin: (): IPWAInterviewQuestion => ({
		question: 'Willkommen zurück!',
		identifier: 'userLogin',
		baseIdentifier: 'userLogin',
		type: InterviewType.QUESTION,
		module: 'login',
		moduleOptions: { type: 'emailOrPhone' },
		editable: false,
		showPrivacyCheckbox: true,
		category: InterviewCategory.Login,
		required: true,
		answerOptions: [
			{
				type: 'grouped-answer',
				answerOptions: [
					{ type: 'text', name: 'email', placeholder: 'E-Mail-Adresse / Telefonnummer' },
					{ type: 'password', name: 'password', placeholder: 'Passwort' }
				]
			},
			{
				type: 'socialLogin'
			}
		]
	}),
	startSignup: (): IPWAInterviewQuestion => ({
		question: 'Wie können wir dich für ein Vorstellungsgespräch erreichen?',
		identifier: 'userSignup',
		baseIdentifier: 'userSignup',
		type: InterviewType.QUESTION,
		module: 'register',
		moduleOptions: { type: 'email' },
		editable: false,
		showPrivacyCheckbox: true,
		category: InterviewCategory.Login,
		answerOptions: [
			{
				type: 'text',
				name: 'email',
				inputType: false,
				placeholder: 'E-Mail-Adresse'
			},
			{
				type: 'socialLogin'
			}
		]
	}),
	startWithName: (): IPWAInterviewQuestion => ({
		question: 'Wie heißt du?',
		identifier: 'startWithName',
		baseIdentifier: 'startWithName',
		type: InterviewType.QUESTION,
		module: 'local-startWithName',
		moduleOptions: {},
		editable: true,
		showPrivacyCheckbox: false,
		required: true,
		category: InterviewCategory.Personal,
		answerOptions: [
			{
				type: 'grouped-answer',
				answerOptions: [
					{
						name: 'firstname',
						placeholder: 'Vorname'
					},
					{
						name: 'lastname',
						placeholder: 'Nachname'
					}
				]
			}
		]
	}),
	startLoginOrSignUp: (): IPWAInterviewQuestion => ({
		question: 'Hast du bereits ein Profil?',
		identifier: 'startLoginOrSignUp',
		baseIdentifier: 'startLoginOrSignUp',
		type: InterviewType.QUESTION,
		module: 'startLoginOrSignUp',
		moduleOptions: {},
		editable: false,
		showPrivacyCheckbox: false,
		category: InterviewCategory.Login
	}),
	passwordSent: (): IPWAInterviewQuestion => ({
		question: 'Du hast eine E-Mail von uns!',
		identifier: 'passwordSent',
		baseIdentifier: 'passwordSent',
		type: InterviewType.QUESTION,
		module: 'passwordSent',
		moduleOptions: {},
		editable: false,
		showPrivacyCheckbox: false,
		required: false,
		category: InterviewCategory.Login
	})
};

export default HokifyApply;
