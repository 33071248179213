<template>
	<div class="hok-answer-container">
		<div
			:key="modelValue.identifier"
			:class="[
				modelValue.type,
				viewState && viewState.mode,
				viewState && viewState.mode === 'match-overview' ? 'inline' : 'inmodal',
				loading ? 'pointer-events-none' : ''
			]"
			:data-msg-id="modelValue.identifier"
			class="hok-answer"
		>
			<form ref="interviewForm" @submit="submitForm">
				<div
					v-for="(option, index) in modelValue.answerOptions"
					:key="matchObjId + '-' + index"
					:class="`option ${option.type}`"
					:data-name="option.name"
				>
					<InterviewAnswerText
						v-if="option.type === 'text'"
						:id="modelValue.identifier"
						ref="interviewAnswerText"
						v-model="internalAnswer"
						:msg-index="msgIndex"
						:option="option"
						:show-privacy-checkbox="modelValue.showPrivacyCheckbox"
						:error-on-request="modelValue.lastError"
						:submit-form="submitForm"
						:question-info="modelValue.questionInfo"
						:disabled="!!loading"
						:keyboard-handler="keyboardHandler"
						:module-options="modelValue.moduleOptions"
						:contact-person="contactPerson"
						:company-name="companyName"
						:match-obj-id="matchObjId"
						:is-match-overview="isMatchOverview"
					/>

					<InterviewAnswerGrouped
						v-else-if="option.type === 'grouped-answer'"
						:id="modelValue.identifier"
						:module-options="modelValue.moduleOptions"
						:msg-index="msgIndex"
						:answer-options="option.answerOptions"
						:disabled="!!loading"
						:required="modelValue.required"
						:keyboard-handler="keyboardHandler"
						:is-match-overview="isMatchOverview"
						:show-privacy-checkbox="modelValue.showPrivacyCheckbox"
					/>

					<div v-else-if="option.type === 'socialLogin'" class="mt-20 sm:mt-8 pb-8">
						<p class="mb-2">Oder anmelden mit:</p>
						<SocialLogin
							:pinia-available="piniaAvailable($nuxt, '$loginStore')"
							:center="false"
							usercase="typeform"
							:show-linkedin-login="!cordova"
							@login-result="socialLoginDone"
						/>
						<HokButton
							class="mt-4"
							is-text
							color="main"
							font-weight="normal"
							@click="openCookieBanner"
						>
							Cookie Einstellungen
						</HokButton>
					</div>

					<div v-else-if="option.type === 'link'" class="mt-4">
						<a v-if="option.cb" class="cursor-pointer" @click="callCb(option.cb)">
							{{ option.label }}
						</a>
						<HokLink v-else to="option.url" target="_blank" styling="typeform">
							{{ option.label }}
						</HokLink>
					</div>

					<InterviewCvSelectionButton
						v-else-if="option.type === 'button' && option.fct === 'createcv'"
						:call-fct="callFct"
						:current-val="modelValue.currentAnswer"
						:option="option"
					/>

					<InterviewAnswerButton
						v-else-if="option.type === 'button' && option.fct !== 'createcv'"
						:id="modelValue.identifier"
						:msg-index="msgIndex"
						:call-fct="callFct"
						:submit-form="submitForm"
						:current-val="modelValue.currentAnswer"
						:option="option"
						:module="modelValue.module"
						:disabled="!!loading"
					/>

					<InterviewAnswerDisplayLongText
						v-else-if="option.type === 'displaylongtext'"
						:id="modelValue.identifier"
						:msg-index="msgIndex"
						:title="option.title"
						:preview="option.preview"
						:text="option.text"
					/>

					<HokRange
						v-else-if="option.type === 'range'"
						:id="modelValue.identifier"
						v-model="internalAnswer"
						:msg-index="msgIndex"
						:option="option"
						:disabled="!!loading"
					/>

					<InterviewAnswerUploadFile
						v-else-if="option.type === 'file'"
						:id="modelValue.identifier"
						:msg-index="msgIndex"
						:current-val="modelValue.currentAnswer"
						:option="option"
						:send-answer="sendAnswer"
						:current-upload-status="currentUploadStatus"
						:disabled="!!loading"
						:parsed-once="parsedOnce"
						@click="
							$trackUserEvent?.('interview_question_select', {
								questionType: modelValue.module.toLowerCase(),
								answerType: 'file-' + option.name
							})
						"
					/>

					<InterviewAnswerLocation
						v-else-if="option.type === 'location'"
						:id="modelValue.identifier"
						ref="interviewAnswerLocation"
						v-model="internalAnswer"
						:msg-index="msgIndex"
						:name="option.name"
						:submit-form="submitForm"
						:disabled="!!loading"
						:keyboard-handler="keyboardHandler"
						:placeholder="option.placeholder"
					/>

					<InterviewAnswerSingleChoice
						v-else-if="option.type === 'singlechoice'"
						:id="modelValue.identifier"
						v-model="internalAnswer"
						:msg-index="msgIndex"
						:option="option"
						:placeholder="getOptionPlaceholder(option)"
						:question="modelValue.question"
						:disabled="!!loading"
						:keyboard-handler="keyboardHandler"
						:is-match-overview="isMatchOverview"
					/>

					<InterviewAnswerMultipleChoice
						v-else-if="option.type === 'multiplechoice'"
						:id="modelValue.identifier"
						:msg-index="msgIndex"
						:current-val="
							(modelValue.moduleOptions && modelValue.moduleOptions[option.name]) ||
							modelValue.currentAnswer
						"
						:name="option.name"
						:values="option.values"
						:maximum-selections="option.maximumSelections"
					/>

					<InterviewAnswerJobAlarm
						v-else-if="option.type === 'jobAlarm'"
						:id="modelValue.identifier"
						ref="interviewAnswerJobAlarm"
						:msg-index="msgIndex"
						:current-val="modelValue.currentAnswer"
						:name="option.name"
						:values="option.values"
						:location="option.location"
						@jobAlarmDone="jobAlarmDone"
					/>

					<InterviewAnswerDate
						v-else-if="option.type === 'datepicker'"
						:id="modelValue.identifier"
						ref="interviewAnswerDate"
						:msg-index="msgIndex"
						:module-options="modelValue.moduleOptions"
						:current-val="modelValue.moduleOptions && modelValue.moduleOptions.date"
						:option="option"
						:submit-form="submitForm"
					/>

					<div v-else>unsupported type: {{ option.type }}</div>
				</div>
				<input ref="interviewSubmitButton" class="hidden" type="submit" />
			</form>
			<div v-if="showUploadStatus" class="border-t-2 border-color-grey-light mt-8 pt-8">
				<p class="font-bold mb-0">Status:</p>
				<p class="block mb-0" v-text="modelValue.currentAnswer" />
				<HokLink :to="modelValue.moduleOptions.file" target="_blank"> herunterladen </HokLink>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import type {
	IAPILoginUser,
	IAPIOwnerSnippet,
	IAPIReAuthResult,
	IInterviewButtonAnswerOption
} from '@hokify/common';
import type {
	IPWAAnswerOptions,
	IPWAInterviewQuestion
} from '@hokify/pwa-core-nuxt3/helpers/apply';
import SocialLogin from '@hokify/login-stack-nuxt3/lib/components/login/SocialLogin.vue';
import { defineComponent, markRaw, defineAsyncComponent } from 'vue';
import type { PropType } from 'vue';

import { mapStores } from 'pinia';
import { piniaAvailable } from '@hokify/shared-components-nuxt3/lib/helpers/piniaStoreAvailable';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import HokRange from '@hokify/shared-components-nuxt3/lib/components/HokRange.vue';
import { useTypeformStore } from '@/stores/typeform';
import { useUserProfileStore } from '@/stores/user-profile';
import type { IInterviewAnswerHook } from '@/components/user/interviewAnswer/IInterviewAnswerHook';
import InterviewAnswerButton from './InterviewAnswerButton.vue';
import InterviewAnswerDisplayLongText from './InterviewAnswerDisplayLongText.vue';
import InterviewAnswerUploadFile from './InterviewAnswerUploadFile.vue';
import InterviewAnswerText from './InterviewAnswerText.vue';
import InterviewAnswerGrouped from './InterviewAnswerGrouped.vue';
import InterviewAnswerDate from './InterviewAnswerDate.vue';
import InterviewAnswerSingleChoice from './InterviewAnswerSingleChoice.vue';
import InterviewAnswerMultipleChoice from './InterviewAnswerMultipleChoice.vue';
import InterviewAnswerLocation from './InterviewAnswerLocation.vue';
import InterviewCvSelectionButton from './InterviewCvSelectionButton.vue';
import InterviewAnswerJobAlarm from './InterviewAnswerJobAlarm.vue';

function hasName(el): el is { name: string } {
	return el?.name !== undefined;
}

export default defineComponent({
	name: 'InterviewAnswer',
	components: {
		HokRange,
		InterviewCvSelectionButton,
		InterviewAnswerDisplayLongText,
		InterviewAnswerButton,
		InterviewAnswerUploadFile,
		InterviewAnswerText,
		InterviewAnswerGrouped,
		InterviewAnswerDate,
		InterviewAnswerSingleChoice,
		InterviewAnswerMultipleChoice,
		InterviewAnswerLocation,
		SocialLogin,
		InterviewAnswerJobAlarm
	},
	emits: ['update:modelValue'],
	data() {
		const cordova = (process.env.cordova as string) || null;
		const lastOption = undefined as undefined | IInterviewButtonAnswerOption;
		const loading = false;
		const currentKeyboardHandler = undefined as any;

		return {
			lastOption,
			currentKeyboardHandler,
			cordova,
			loading,
			piniaAvailable,
			EventBus
		};
	},
	beforeUnmount() {
		this.EventBus.$off('end-go-to-exp');
		this.EventBus.$off('end-go-to-edu');
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		internalAnswer: {
			get(): string {
				return typeof this.modelValue.currentAnswer === 'string'
					? this.modelValue.currentAnswer
					: '';
			},
			set(value) {
				this.$emit('update:modelValue', value);
			}
		},
		interviewAnswerTypeMap(): Record<string, IInterviewAnswerHook> {
			return {
				text: this.interviewAnswerText?.[0],
				location: this.interviewAnswerLocation?.[0],
				jobAlarm: this.interviewAnswerJobAlarm?.[0],
				datepicker: this.interviewAnswerDate?.[0],
				file: this.interviewAnswerUploadFile?.[0]
			};
		},
		acceptedPrivacy: {
			get() {
				if (!this.userProfileStore.obj || !this.userProfileStore.obj?.privacy) {
					this.userProfileStore.updatePrivacy({ user_register: false });
				}

				return (
					this.userProfileStore.obj &&
					this.userProfileStore.obj.privacy &&
					this.userProfileStore.obj.privacy.user_register
				);
			},
			set(value) {
				this.userProfileStore.updatePrivacy({ user_register: value });
			}
		},
		interviewAnswerText() {
			return this.$refs.interviewAnswerText as any;
		},
		interviewAnswerLocation() {
			return this.$refs.interviewAnswerLocation as any;
		},
		interviewAnswerJobAlarm() {
			return this.$refs.interviewAnswerJobAlarm as any;
		},
		interviewAnswerDate() {
			return this.$refs.interviewAnswerDate as any;
		},
		interviewAnswerUploadFile() {
			return this.$refs.interviewAnswerUploadFile as any;
		},
		interviewForm() {
			return this.$refs.interviewForm as any;
		},
		interviewSubmitButton() {
			return this.$refs.interviewSubmitButton as any;
		},
		showUploadStatus(): boolean {
			return !!(
				(this.user?.testerGroup !== 'A' &&
					['cv', 'certificate', 'document', 'nationality', 'DOCUMENT_KNOCKOUT'].includes(
						this.modelValue.module
					) &&
					this.modelValue.currentAnswer &&
					this.modelValue.moduleOptions?.file) ||
				!!(
					['certificate', 'document', 'nationality', 'DOCUMENT_KNOCKOUT'].includes(
						this.modelValue.module
					) &&
					this.modelValue.currentAnswer &&
					this.modelValue.moduleOptions?.file
				)
			);
		},
		...mapStores(useTypeformStore, useUserProfileStore)
	},
	created() {
		if (!this.modelValue.answerOptions || !Array.isArray(this.modelValue.answerOptions)) {
			console.error(
				'msg has no answerOptions:',
				this.modelValue,
				Array.isArray(this.modelValue.answerOptions)
			);
		}
	},
	methods: {
		getOptionPlaceholder(option: IPWAAnswerOptions) {
			if ('placeholder' in option) {
				return option.placeholder;
			}

			return undefined;
		},
		async submitInterviewForm() {
			// this.interviewForm.submit(); <-- this is only supported from safari > 16 https://caniuse.com/?search=requestSubmit%20
			// this.interviewForm.dispatchEvent(new SubmitEvent('submit')); // does not work in older browser versions https://caniuse.com/mdn-api_submitevent_submitter
			// because the other options are not supported by all browsers I had to use a hidden button in the form and move the
			// answer handling into the preventDefault block in "submitForm". This works because only in case of the usage of the submit button
			// there will be an event. In all other cases the hooks will be ignored, which must be this way because the hooks
			// themselves call "submitForm" but without an event
			this.interviewSubmitButton.click();
		},
		async submitForm(e?: Event, nameOrPayload?: string | Record<string, string>, value?: any) {
			// prevent page reload after submit
			if (e && typeof e.preventDefault === 'function') {
				e.preventDefault();

				const answerOption = this.modelValue.answerOptions?.find(
					answerOpt => hasName(answerOpt) && answerOpt.name && answerOpt.type !== 'button'
				);
				const { type } = answerOption || {};
				const hook = type && this.interviewAnswerTypeMap[type];

				const submitHandled = (await hook?.handleSubmit()) || false;
				if (submitHandled) {
					return;
				}
			}

			if (this.modelValue.identifier === 'startWithName') {
				// extract firstname and lastname from formData ant save it into store
				// const formData = new FormData(e.currentTarget);
				const formData = new FormData(this.interviewForm);
				const nameArray: string[] = [];
				for (const entry of (formData as any).entries()) {
					nameArray.push(entry[1]);
				}
				this.typeformStore.setFirstName(nameArray[0]);
				this.typeformStore.setLastName(nameArray[1]);
				await this.sendAnswer('startLogin');
				return;
			}

			try {
				this.loading = true;

				const formData = new FormData(this.interviewForm);

				for (const [key] of formData.entries()) {
					if (key === 'reason' || key === 'description' || key === 'answer') {
						formData.set(key, this.internalAnswer);
					}
				}

				// used for e.g. when uploading a file gets skipped via the interviewAnswerButton
				if (e) {
					const submittor = e.target?.[0];
					if (submittor?.type === 'submit') {
						formData.append(submittor.name, submittor.value);
					}
				}

				if (typeof nameOrPayload === 'string' && value) {
					formData.append(nameOrPayload, value);
				}

				// special logic for sending hok-recaptcha token
				else if (typeof nameOrPayload === 'object') {
					Object.entries(nameOrPayload).forEach(([key, val]) => {
						formData.append(key, val);
					});
				}

				this.keyboardHandler('blur', false);

				const res = await this.sendAnswer(
					this.modelValue.identifier,
					formData,
					false,
					this.modelValue.currentAnswer
				);

				return res;
			} finally {
				this.loading = false;
			}
		},
		async callFct(fct, done, option?: IInterviewButtonAnswerOption) {
			if (option) {
				this.lastOption = option;
			}

			// if we are done, select was called before already
			if (!done) {
				this.$trackUserEvent?.('interview_question_select', {
					questionType: this.modelValue.module.toLowerCase(),
					answerType: `fct-${fct}`
				});
			}

			switch (fct) {
				case 'cancel':
					if (this.$page && this.$page.isOpen) {
						this.$page.goBack();
					} else {
						this.$router.push({ path: '/pwa' });
					}
					break;
				case 'createcv': {
					if (done) {
						try {
							this.loading = true;
							if (!this.lastOption) {
								throw new Error('no last option set');
							}
							const formData = new FormData();
							formData.append(this.lastOption.name, JSON.stringify(true));
							await this.sendAnswer(this.modelValue.identifier, formData);
						} finally {
							this.loading = false;
						}
						return;
					}
					const hokifyCv = markRaw(defineAsyncComponent(() => import('@/pages/pwa/QuickCv.vue')));
					try {
						await this.$page.push(
							hokifyCv,
							{
								showDocuments: false,
								standalone: false
							},
							{
								pageTitle: 'Einfach Lebenslauf erstellen',
								name: 'hokify-cv',
								done: () => this.callFct('createcv', true)
							}
						);
					} catch (err) {
						this.$nuxt.$errorHandler(err);
					}
					break;
				}
				case 'profilevideo': {
					if (done) {
						try {
							this.loading = true;
							if (!this.lastOption) {
								throw new Error('no last option set');
							}
							const formData = new FormData();
							formData.append(this.lastOption.name, JSON.stringify(true));
							await this.sendAnswer(this.modelValue.identifier, formData);
						} finally {
							this.loading = false;
						}
						return;
					}
					const videoPage = markRaw(
						defineAsyncComponent(() => import('@/pages/pwa/videoUploadPage.vue'))
					);
					try {
						await this.$page.push(
							videoPage,
							{ videoTrackingCategory: 'user-card-swipe-interaction' },
							{
								pageTitle: 'Videovorstellung',
								name: 'videoApplication',
								done: () => this.callFct('profilevideo', true)
							}
						);
					} catch (err) {
						this.$nuxt.$errorHandler(err);
					}
					break;
				}
				case 'addposition': {
					if (done) {
						try {
							this.loading = true;
							if (!this.lastOption) {
								throw new Error('no last option set');
							}

							const formData = new FormData();
							formData.append(this.lastOption.name, JSON.stringify(true));
							await this.sendAnswer(this.modelValue.identifier, formData, true);
						} finally {
							this.loading = false;
						}
						return;
					}
					const experienceComponent = markRaw(
						defineAsyncComponent(() => import('../hokifyCvEdit/Experience.vue'))
					);
					try {
						await this.$page.push(
							experienceComponent,
							{
								fullDate: true,
								isOnboarding: false
							},
							{
								pageTitle: 'Berufserfahrung hinzufügen',
								name: 'add-experience',
								done: () => {
									this.EventBus.$off('end-go-to-exp');
								}
							}
						);
						this.EventBus.$on('end-go-to-exp', () =>
							this.callFct('addposition', true).then(() => this.$page.goBack())
						);
					} catch (err) {
						this.$nuxt.$errorHandler(err);
					}

					break;
				}
				case 'addeducation': {
					if (done) {
						try {
							this.loading = true;
							if (!this.lastOption) {
								throw new Error('no last option set');
							}

							const formData = new FormData();
							if (this.lastOption) {
								formData.append(this.lastOption.name, JSON.stringify(true));
							}
							await this.sendAnswer(this.modelValue.identifier, formData, true); // sendAnswer(msgId, formData, refresh, value) {
						} finally {
							this.loading = false;
						}
						return;
					}

					const educationComponent = markRaw(
						defineAsyncComponent(() => import('../hokifyCvEdit/Education.vue'))
					);
					try {
						await this.$page.push(
							educationComponent,
							{
								fullDate: true,
								isOnboarding: false
							},
							{
								pageTitle: 'Ausbildung hinzufügen',
								name: 'add-education',
								done: () => {
									this.EventBus.$off('end-go-to-edu');
								}
							}
						);
						this.EventBus.$on('end-go-to-edu', () => {
							this.callFct('addeducation', true).then(() => this.$page.goBack());
						});
					} catch (err) {
						this.$nuxt.$errorHandler(err);
					}
					break;
				}
				default:
					throw new Error(`invalid function: ${fct}`);
			}
		},
		callCb(cb) {
			cb();
		},
		keyboardHandler(type, _targetEvent, timeout = 150) {
			// timeout is needed for "non blocking" the "button click" event!
			if (this.currentKeyboardHandler) {
				clearTimeout(this.currentKeyboardHandler);
			}

			if (this.viewState) {
				// if keyboard is open, we go into a pending state
				if (this.viewState.keyboardOpen) {
					// TODO check if we still need this and rewrite - otherwise remove!
					// eslint-disable-next-line vue/no-mutating-props
					this.viewState.keyboardOpen = 'closepending';
					// Vue.set(this.viewState, 'keyboardOpen', 'closepending');
				}
			}
			this.currentKeyboardHandler = setTimeout(() => {
				this.currentKeyboardHandler = false;
				if (
					this.viewState &&
					(!this.viewState.keyboardOpen || this.viewState.keyboardOpen === 'closepending')
				) {
					// TODO check if we still need this and rewrite - otherwise remove!
					// eslint-disable-next-line vue/no-mutating-props
					this.viewState.keyboardOpen = type === 'focus';
					// Vue.set(this.viewState, 'keyboardOpen', type === 'focus');
				}
			}, timeout);
		},
		async socialLoginDone(loginResult: IAPIReAuthResult & { loginMethod: string }) {
			this.loading = true;
			await this.sendAnswer('social-login-done', {
				loginResult,
				loginMethod: loginResult.loginMethod
			});
			this.loading = false;
		},
		async jobAlarmDone() {
			this.loading = true;
			await this.sendAnswer('job-alarm-done');
			this.loading = false;
		},
		openCookieBanner() {
			this.EventBus.$emit('show-cookie-banner');
		}
	},
	props: {
		companyName: { type: String, default: '' },
		contactPerson: {
			type: Object as PropType<IAPIOwnerSnippet>,
			required: false,
			default: () => {}
		},
		matchObjId: { type: String, default: () => '' },
		modelValue: { type: Object as PropType<IPWAInterviewQuestion>, required: true },
		sendAnswer: { type: Function, required: true },
		msgIndex: { type: Number, required: true },
		answered: { type: [Boolean, String, Object], default: undefined },
		currentUploadStatus: { type: [Boolean, Number], default: false },
		jobRegion: { type: String, required: false, default: () => '' },
		isMatchOverview: { type: Boolean, default: false },
		viewState: { type: Object, default: () => {} },
		parsedOnce: { type: Boolean, default: false }
	}
});
</script>

<style scoped lang="scss">
.answered {
	display: block;
	font-weight: bold;
	color: $color-text;
	margin-bottom: $s2;
	width: 100%;
	word-wrap: break-word;
}
</style>
