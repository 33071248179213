<template>
	<div class="radios">
		<HokRadioGroup v-if="values.length <= 8" v-model="internalValue">
			<HokRadio
				v-for="(radio, index) in values"
				:id="`singlechoice-${id}-${msgIndex}-${index}`"
				:key="index"
				:overwrite-checked="overwriteChecked(radio.fct)"
				:disabled="disabled"
				:name="option.name"
				:value="radio.value"
				:inline="false"
				styling="default"
			>
				<template v-if="radio.fct === 'custom'" #custom>
					<HokInput
						:id="`singlechoice-inputinner-${id}-${msgIndex}-${index}`"
						:model-value="customValue"
						:placeholder="radio.label || placeholder"
						class="pt-0 mb-0"
						@update:model-value="customValue = $event"
					/>
				</template>
				<template v-else #custom>
					{{ radio.label }}
				</template>
			</HokRadio>
			<input
				v-if="customValue && internalValue"
				type="hidden"
				:name="`${option.name}-TEXT`"
				:value="internalValue"
			/>
		</HokRadioGroup>
		<HokSelect
			v-else-if="values.length > 8 && values.length <= 20"
			:value-promise="hokSelectValuePromise"
			:submit-value="option.name"
			:choose-text="placeholder || 'Bitte auswählen...'"
			key-name="range"
			:value="internalLabel"
			:class="{ 'mb-2': isMatchOverview }"
			@input="internalValue = $event.id"
		/>
		<Autocomplete
			v-else
			:id="`singlechoice-autocomplete-${id}-${msgIndex}`"
			v-model="internalLabel"
			:name="option.name"
			:value-promise="autocompleteValuePromise"
			:question="question"
			:min-length="0"
			:must-select="false"
			value-label="label"
			value-id="value"
			:max-number-of-suggestions="50"
			@focus="keyboardHandler('focus', $event)"
			@blur="keyboardHandler('blur', $event)"
		>
			{{ placeholder || 'Nachricht eingeben...' }}
		</Autocomplete>
		<div class="flex justify-end">
			<HokButton v-if="isMatchOverview" submit :disabled="disabled" color="main" fullwidth="mobile">
				Speichern
			</HokButton>
		</div>
	</div>
</template>

<script lang="ts">
import HokRadio from '@hokify/shared-components-nuxt3/lib/components/HokRadio.vue';
import HokRadioGroup from '@hokify/shared-components-nuxt3/lib/components/HokRadioGroup.vue';
import Autocomplete from '@hokify/shared-components-nuxt3/lib/components/Autocomplete.vue';
import HokSelect from '@hokify/shared-components-nuxt3/lib/components/HokSelect.vue';
import HokInput from '@hokify/shared-components-nuxt3/lib/components/HokInput.vue';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import type { IInterviewSingleChoiceAnswerOption } from '@hokify/common';

export default defineComponent({
	name: 'InterviewAnswerSingleChoice',
	components: {
		HokInput,
		HokRadioGroup,
		HokRadio,
		Autocomplete,
		HokSelect
	},
	emits: ['update:modelValue'],
	data() {
		return {
			customValueInternal: ''
		};
	},
	methods: {
		async hokSelectValuePromise() {
			return this.values.map(v => ({ value: v.label, id: v.value }));
		},
		async autocompleteValuePromise(sel) {
			return this.values.filter(v => v.label?.toLowerCase().includes(sel?.toLowerCase().trim()));
		},
		overwriteChecked(fct?: string): boolean | null {
			if (!this.customValueInternal || fct !== 'custom') {
				return null;
			}

			return this.customValueInternal === this.internalValue;
		}
	},
	props: {
		id: { type: String, required: true },
		msgIndex: { type: Number, required: true },
		question: { type: String, default: '' },
		placeholder: { type: String, default: '' },
		modelValue: { type: String, default: '' },
		disabled: { type: Boolean },
		option: { type: Object as PropType<IInterviewSingleChoiceAnswerOption>, required: true },
		keyboardHandler: { type: Function, default: () => ({}) },
		isMatchOverview: { type: Boolean, default: false }
	},
	computed: {
		values() {
			// Not IInterviewSingleChoiceAnswerOption type conform, but we don't want add possible misstype breaking changes
			return this.option.values as { label: string; value: string; fct?: string }[];
		},
		customValue: {
			get() {
				if (!this.customValueInternal && this.option.prefillValue && this.option.defaultValue) {
					return this.option.defaultValue;
				}

				return this.customValueInternal;
			},
			set(value = '') {
				this.customValueInternal = value;
				this.internalValue = value;
			}
		},
		internalLabel: {
			get() {
				const existingLabel = this.values.find(
					v => v.value?.toLowerCase() === this.modelValue?.toLowerCase()
				)?.label;

				return existingLabel ?? this.modelValue ?? '';
			},
			set(label: IInterviewSingleChoiceAnswerOption['values'][number]['label']) {
				// Best matching logic for label is in the internalValue set
				// Due to intercompatibility with the HokSelect component
				this.internalValue = label;
			}
		},
		internalValue: {
			get() {
				return this.modelValue;
			},
			set(rawValue: string | boolean) {
				const value = typeof rawValue === 'boolean' ? '' : rawValue;

				const valueFound = this.values.find(v => v.value === value);
				const labelFound = this.values.find(v => v.value === value);

				const existingValue = valueFound?.value ?? labelFound?.value;

				if (value && this.customValueInternal !== value) {
					this.customValueInternal = '';
				}

				this.$emit('update:modelValue', existingValue ?? value ?? '');
			}
		}
	}
});
</script>
