<template>
	<!--:resolve-address="resolveAddress"-->
	<LocationAutocomplete
		:id="`location-${id}-${msgIndex}`"
		ref="autocomplete"
		v-model="myCurrentVal"
		:name="name"
		current-location-button
		:disabled="disabled"
		:resolve-geo-coords="locationStore.resolveGeoCoords"
		require-postal
		only-cities
		@change="checkIfSubmit"
	>
		{{ placeholder }}
	</LocationAutocomplete>
</template>

<script lang="ts">
import LocationAutocomplete from '@hokify/shared-components-nuxt3/lib/components/LocationAutocomplete.vue';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { useLocationStore } from '@/stores/location';

function hasBlur(obj: any): obj is HTMLElement {
	return typeof obj.blur === 'function';
}

function isFocused(obj: any) {
	return !!obj?.focused;
}

export default defineComponent({
	name: 'InterviewAnswerLocation',
	components: {
		LocationAutocomplete
	},
	emits: ['update:modelValue'],
	data() {
		return {
			allowSubmit: false
		};
	},
	computed: {
		myCurrentVal: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit('update:modelValue', value);
			}
		},
		...mapStores(useLocationStore)
	},
	methods: {
		blur($event) {
			if (this.keyboardHandler) {
				this.keyboardHandler('blur', $event);
			}
		},
		focus($event) {
			if (this.keyboardHandler) {
				this.keyboardHandler('focus', $event);
			}
		},
		checkIfSubmit(_placeName, _place, postalIsMissing) {
			this.allowSubmit = !postalIsMissing;
		},
		async handleSubmit(): Promise<boolean> {
			if (isFocused(this.$refs.autocomplete)) {
				if (hasBlur(this.$refs.autocomplete)) {
					this.$refs.autocomplete.blur();
				}
			}

			// if allowed, submit the form - if not - return 'false' for default logic to kick in again
			if (this.allowSubmit && this.submitForm) {
				this.submitForm();
			} else {
				return false;
			}

			return true;
		}
	},
	props: {
		id: { type: String, required: true },
		msgIndex: { type: Number, required: true },
		name: { type: String, default: () => '' },
		placeholder: { type: String, default: '' },
		modelValue: { type: String, default: '' },
		submitForm: {
			type: Function as PropType<
				(e?: Event, nameOrPayload?: string | Record<string, string>, value?: any) => unknown
			>,
			default: () => {}
		},
		keyboardHandler: { type: Function, default: () => ({}) },
		disabled: { type: Boolean }
	}
});
</script>

<style lang="scss" scoped>
// eslint-disable-next-line vue-scoped-css/no-unused-selector
.pac-container {
	top: 38px !important;
	left: 0 !important;
}
</style>
