<template>
	<div>
		<p class="inline italic">{{ preview }}</p>
		<HokButton
			v-if="text && text.length > 0"
			:id="`longtextbutton-${id}-${msgIndex}`"
			is-text
			class="mb-1"
			@click="showText"
		>
			mehr anzeigen
		</HokButton>
	</div>
</template>

<script lang="ts">
import { defineComponent, markRaw, defineAsyncComponent } from 'vue';

export default defineComponent({
	name: 'InterviewAnswerDisplayLongText',
	methods: {
		async showText() {
			const showTextComponent = markRaw(
				defineAsyncComponent(() => import('../../../pages/apply/ShowText.vue'))
			);
			try {
				await this.$page.push(
					showTextComponent,
					{ text: this.text },
					{
						mode: 'modal',
						modalHeading: this.title,
						name: 'showLongText'
					}
				);
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		}
	},
	props: {
		id: { type: String, required: true },
		msgIndex: { type: Number, required: true },
		title: { type: String, required: true },
		preview: { type: String, required: true },
		text: { type: String, required: true }
	}
});
</script>
