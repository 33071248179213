<template>
	<div class="checkboxes">
		<span v-if="!values || values.length === 0">
			Noch keine Optionen vorhanden. Bitte füge zumindest einen Eintrag hinzu!
		</span>
		<div v-else class="labels-outer">
			<div v-for="(checkbox, index) in values" :key="index">
				<HokCheckbox
					:id="`multichoice-${id}-${msgIndex}-${index}`"
					v-model="checkbox.value"
					:name="`${name}[]`"
					:disabled="disabled"
					:checked="currentVals.indexOf(checkbox.label) !== -1"
					styling="default"
				>
					{{ checkbox.label }}
				</HokCheckbox>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import HokCheckbox from '@hokify/shared-components-nuxt3/lib/components/HokCheckbox.vue';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'InterviewAnswerMultipleChoice',
	components: {
		HokCheckbox
	},
	data() {
		const currentVals: string[] = [];

		return {
			currentVals,
			countSelected: 0
		};
	},
	created() {
		let currentVals: string[] = [];
		if (this.currentVal && typeof this.currentVal === 'string') {
			currentVals = this.currentVal.split(',');
			this.currentVals = currentVals.map(val => (val as any).trim());
		}
	},
	methods: {
		// TODO: @suits-at check if this is needed at all
		/* checkMaximumSelections() {
			if (this.maximumSelections && this.maximumSelections > 0) {
				if (this.countSelected < this.maximumSelections) {
					this.countSelected += 1;
					return true;
				}
				this.$snack.danger({
					text: `Maximal ${this.maximumSelections} Antworten möglich.`,
					button: 'verstanden'
				});
				return false;
			}
			return true;
		},
		uncheckMaximumSelections() {
			if (this.maximumSelections && this.maximumSelections > 0) {
				if (this.countSelected >= 1) {
					this.countSelected -= 1;
					return true;
				}
				return false;
			}
			return true;
		} */
	},
	props: {
		id: { type: String, required: true },
		msgIndex: { type: Number, required: true },
		name: { type: String, default: () => '' },
		values: { type: Array, default: () => [] },
		disabled: { type: Boolean },
		currentVal: { type: [String, Date, Boolean], default: '' },
		maximumSelections: { type: Number, required: false, default: () => {} }
	}
});
</script>
