<template>
	<div v-if="msg.module === 'startLoginOrSignUp'">
		<p class="text-color-main text-xl">{{ msg.question }}</p>
		<p>Melde dich mit deinem Profil an, um dich einfacher zu bewerben.</p>
		<div
			class="flex items-center cursor-pointer border-2 border-color-grey-lightest rounded-lg max-w-sm py-4 px-3 mt-4"
			data-cy="profile-exists"
			@click="$emit('do-login')"
		>
			<NuxtImg
				src="/interview-login.png"
				alt="Lebenslauf hochladen"
				class="aspect-square h-28 w-28"
			/>
			<div>
				<p class="mb-3 text-color-main font-bold">Ja, ich habe ein Profil</p>
				<p class="text-color-grey-medium text-sm mb-0">
					Greife auf deine gespeicherten Erfahrungen und Ausbildungen zu.
				</p>
			</div>
		</div>
		<div
			class="flex items-center cursor-pointer border-2 border-color-grey-lightest rounded-lg max-w-sm py-4 px-3 mt-4"
			data-cy="no-profile"
			@click="$emit('do-signup')"
		>
			<NuxtImg src="/interview-signup.png" alt="Registrieren" class="aspect-square h-28 w-28" />
			<div>
				<p class="mb-3 text-color-main font-bold">Nein, ich habe kein Profil</p>
				<p class="text-color-grey-medium text-sm mb-0">
					Erstelle ein neues Profil für alle Bewerbungen.
				</p>
			</div>
		</div>
	</div>
	<div v-else-if="msg.module === 'passwordSent'">
		<p class="text-color-main text-xl">{{ msg.question }}</p>
		<p>Wir haben soeben eine E-Mail an</p>
		<p class="font-bold text-color-main">{{ loginEmail }}</p>
		<p>
			geschickt. Um mit deiner Bewerbung fortzufahren, klicke bitte auf den Link in der E-Mail und
			bestätige deine Kontaktdaten. <br /><br />
			Überprüfe bitte auch deinen <span class="text-color-main">Spam-Ordner</span>, falls du die
			E-Mail nicht findest.
		</p>
		<p class="font-bold">Du findest diese E-Mail auch nicht im Spam-Ordner?</p>
		<HokButton v-if="!linkTimeoutActive" is-text color="main" @click="resendEmail"
			>E-Mail erneut versenden</HokButton
		>
		<p v-else class="text-color-grey font-bold">
			Nachricht erneut versenden in {{ timeout }} Sekunden
		</p>
	</div>
	<div v-else-if="msg.module === 'pic'" class="mb-6">
		<p class="text-color-main text-xl mb-4">Füge ein Bewerbungsfoto hinzu</p>
		<p class="flex text-sm mb-2">
			<HokIcon name="icon:swipe-right" color="main" :size="4" class="mr-2" /> Du bist gut erkennbar
		</p>
		<p class="flex text-sm mb-2">
			<HokIcon name="icon:swipe-right" color="main" :size="4" class="mr-2" /> Neutraler Hintergrund
		</p>
		<p class="flex text-sm mb-0">
			<HokIcon name="icon:swipe-left" color="text" :size="4" class="mr-2" /> Kein Urlaubsfoto
		</p>
	</div>
	<div v-else-if="msg.module === 'profilevideo'" class="text-center sm:text-left mx-auto">
		<div class="flex justify-center sm:justify-start sm:mb-4">
			<NuxtImg
				src="/girl-selfie.png"
				class="align-middle inline-block rounded-full w-20 h-20 xs-w-32 xs-h-32 border-2 border-color-main sm:mt-16"
			/>
		</div>
		<p>
			<span :class="{ block: $isMobile.any }" class="text-color-main font-bold"
				>Bewerbungsvideo: </span
			>Punkte mit einem kurzen Video!
		</p>
		<p class="mb-0">
			Mache jetzt <span class="text-color-main">ein Selfie-Video </span> und stelle dich kurz
			persönlich vor! Damit hebst du dich von deinen MitbewerberInnen ab.
		</p>
	</div>
	<div v-else-if="msg.module === 'cvParsing'" class="flex flex-col items-center mx-auto">
		<div class="text-color-main text-xl">
			<BeautifyText :text="msg.question" />
		</div>
		<CvParsingProgress
			:cv-loading="cvLoading"
			:cv-parsed="true"
			:avg-parsing-time="60"
			:checkmark-thresholds="[7, 9, 8, 9, 8, 8, 11]"
			@parsing-done.once="$emit('parsing-done')"
		/>
		<HokModal :show-close-button="false" :width="$isMobile.any ? '95%' : '350px'" name="cv-error">
			<h3 class="mb-4">{{ uploadError.title }}</h3>
			<p class="mb-10">{{ uploadError.text }}</p>
			<div class="flex justify-center">
				<HokButton color="main" full-width="always" @click="cvErrorResolve(uploadError.emitName)">{{
					uploadError.button
				}}</HokButton>
			</div>
		</HokModal>
	</div>
	<div v-else>
		<div class="text-color-main text-xl" :class="{ 'text-center': msg.action }">
			<BeautifyText :text="msg.question" />
			<a v-if="msg.action" @click="msg.action.cb">
				{{ msg.action.text }}
			</a>
		</div>
		<!-- special case for motivation question  -->
		<p v-if="msg.questiontype === 'motivation'" class="-mt-4">
			Wähle unseren Vorschlag oder schreibe selbst eine Nachricht.
		</p>
		<!-- special case for joblevel description question  -->
		<p v-if="msg.module === 'JOBLEVEL' && msg.identifier.split('-')[1] === '9'" class="-mt-4">
			Beschreibe, was du getan hast und welche Ziele du erreicht hast
		</p>
	</div>
</template>

<script lang="ts">
import BeautifyText from '@hokify/shared-components-nuxt3/lib/components/BeautifyText.vue';
import type { IAPILoginUser } from '@hokify/common';
import { defineComponent } from 'vue';
import type { IPWAInterviewQuestion } from '@hokify/pwa-core-nuxt3/helpers/apply';
import type { PropType } from 'vue';
import { mapStores } from 'pinia';
import { sessionStorageTest } from '@hokify/shared-components-nuxt3/lib/helpers/sessionstorage';
import { useUserProfileStore } from '@/stores/user-profile';
import { useLoginStore } from '@/stores/login';
import CvParsingProgress from '@/components/user/CvParsingProgress.vue';

export default defineComponent({
	name: 'Question',
	components: { BeautifyText, CvParsingProgress },
	emits: ['go-to-cv-question', 'interview-done', 'parsing-done', 'do-signup', 'do-login'],
	data() {
		const uploadError = { title: '', text: '', button: '', emitName: '' };
		const waitForParsing = true;
		const intervalId = undefined as NodeJS.Timeout | undefined;
		const loginEmail = this.$loginStore.loginIdentifier;
		const redirect = this.$loginStore.loginRedirect;

		return {
			uploadError,
			waitForParsing,
			cvLoading: true,
			timeout: 0,
			newLinkRequested: false,
			intervalId,
			loginEmail,
			redirect
		};
	},
	mounted() {
		if (this.msg.module === 'cvParsing') {
			setTimeout(() => {
				if (this.cvParsingDone) {
					this.userProfileStore.updateCvParsingDone(false);
					this.cvLoading = false;
				} else if (this.cvParsingError) {
					this.handleUploadError(this.cvParsingError);
				}
				this.waitForParsing = false;
			}, 5000);

			// when no error is received we let the user skip to the next step
			setTimeout(() => {
				this.handleUploadError();
				this.$trackUserEvent?.('cvparsing_interview_timeout', {});
			}, 120000);
		} else if (this.msg.module === 'passwordSent') {
			if (sessionStorageTest()) {
				const timeout =
					(sessionStorage.getItem('new-link-timeout') &&
						Number(sessionStorage.getItem('new-link-timeout'))) ||
					0;
				const time = Date.now();
				if (timeout > time) {
					this.timeout = Math.round((timeout - time) / 1000);
					this.startCountdown();
				}
			}
		}
	},
	beforeUnmount() {
		clearInterval(this.intervalId);
	},
	methods: {
		onCvParsingStatusChange(newVal: boolean) {
			if (newVal && !this.waitForParsing) {
				this.userProfileStore.updateCvParsingDone(false);
				this.cvLoading = false;
			}
		},
		onCvParsingErrorChange(newVal: boolean) {
			if (newVal && !this.waitForParsing) {
				this.handleUploadError(this.cvParsingError);
			}
		},
		handleUploadError(error?: string) {
			if (error === 'cv-parsing-error-not-a-cv') {
				this.uploadError = {
					title: 'Bitte Lebenslauf hochladen',
					text: 'Scheinbar war das hochgeladene Dokument kein Lebenslauf.',
					button: 'Neues Dokument hochladen',
					emitName: 'go-to-cv-question'
				};
			} else {
				this.uploadError = {
					title: 'Da ist etwas schief gelaufen!',
					text: 'Wir konnten deinen Lebenslauf nicht in ein Profil umwandeln. Du kannst deine Bewerbung dennoch abschließen.',
					button: 'Zum nächsten Schritt',
					emitName: 'parsing-done'
				};
			}
			this.$modal.show('cv-error');
		},
		cvErrorResolve(emitName: 'parsing-done' | 'go-to-cv-question' | 'interview-done') {
			this.userProfileStore.updateCvParsingError({
				error: undefined,
				notCvErrorSeen: this.cvParsingError === 'cv-parsing-error-not-a-cv' ? true : undefined
			});
			this.$modal.hide('cv-error');
			this.$emit(emitName);
		},
		async resendEmail() {
			try {
				await this.loginStore.resendServiceUnavailableEmail({
					loginIdentifier: this.loginEmail,
					appMode: 'b2c',
					redirect: this.redirect
				});

				if (sessionStorageTest()) {
					sessionStorage.setItem('new-link-timeout', (Date.now() + 60000).toString());
				}
				this.timeout = 60;
				this.newLinkRequested = true;
				this.startCountdown();
			} catch (err) {
				this.$errorHandler.call(this, err);
			}
		},
		startCountdown() {
			this.intervalId = setInterval(() => {
				if (this.timeout > 0) {
					this.timeout -= 1;
				} else {
					clearInterval(this.intervalId);
				}
			}, 1000);
		}
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		cvParsingDone(): boolean {
			return this.userProfileStore.cvParsingDone.done;
		},
		cvParsingError(): string | undefined {
			return this.userProfileStore.cvParsingError?.error;
		},
		linkTimeoutActive(): boolean {
			return this.timeout > 0;
		},
		...mapStores(useUserProfileStore, useLoginStore)
	},
	watch: {
		cvParsingDone: [
			{
				handler: 'onCvParsingStatusChange'
			}
		],
		cvParsingError: [
			{
				handler: 'onCvParsingErrorChange'
			}
		]
	},
	props: {
		msg: { type: Object as PropType<IPWAInterviewQuestion>, required: true },
		jobRegion: { type: String, required: false, default: () => '' }
	}
});
</script>
<style scoped lang="scss">
@media (min-width: 375px) {
	.xs-w-32 {
		width: 8rem;
	}
	.xs-h-32 {
		height: 8rem;
	}
}
</style>
