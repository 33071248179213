<template>
	<div>
		<input :value="option.type" type="hidden" name="type" />
		<input :value="option.title" type="hidden" name="title" />
		<input
			:id="`upload-${id}-${msgIndex}`"
			ref="uploadfield"
			:data-docname="option.name"
			:accept="option.accept"
			:disabled="disabled"
			:autofocus="!$isMobile.any"
			type="file"
			class="inputfile hidden"
			name="media"
			@change="handleUpload($event)"
			@click="$emit('click', $event)"
		/>
		<div
			v-if="option.name === 'profilepic'"
			class="border-dashed border-2 border-color-grey-light rounded-lg mb-16"
		>
			<ProfileImageEditMode
				v-if="option.name === 'profilepic' && user"
				:user="user"
				class="mx-auto py-3"
			/>
		</div>
		<div
			v-else-if="option.name === 'cv'"
			class="flex items-center space-x-6 cursor-pointer border-2 border-color-grey-lightest rounded-lg max-w-sm py-4 px-3"
			@click="clickUpload"
		>
			<NuxtImg src="/cv-upload.png" alt="Lebenslauf hochladen" class="aspect-square" />
			<div v-if="userCv">
				<p class="mb-3 text-color-main font-bold">
					{{
						currentUploadStatus ? 'Lädt hoch: ' + currentUploadStatus + '%' : 'Lebenslauf ersetzen'
					}}
				</p>
				<p class="text-color-grey-medium mb-0 text-sm">
					Dein bereits hochgeladener<br />
					Lebenslauf wird ersetzt:<br /><br />
					<span class="break-all">{{ userCv.originalName }}</span>
				</p>
			</div>
			<div v-else>
				<p class="mb-3 text-color-main font-bold">
					{{
						currentUploadStatus ? 'Lädt hoch: ' + currentUploadStatus + '%' : option && option.label
					}}
				</p>
				<p class="text-color-grey-medium mb-0 text-sm">
					Einfach PDF hochladen und wir<br />
					erstellen dein Profil für dich.
				</p>
			</div>
		</div>
		<HokButton
			v-else
			:id="`submitupload-${id}-${msgIndex}`"
			:outline="option.styling === 'outline'"
			:is-text="option.styling === 'text'"
			color="main"
			class="mt-4"
			@click="clickUpload"
		>
			{{ currentUploadStatus ? 'Lädt hoch: ' + currentUploadStatus + '%' : option && option.label }}
		</HokButton>
	</div>
</template>

<script lang="ts">
import { IAPILoginUser } from '@hokify/common';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import ProfileImageEditMode from '@hokify/pwa-core-nuxt3/components/hokifycv/ProfileImageEditMode.vue';
import { useUserProfileStore } from '@/stores/user-profile';
import { useFeatureFlagStore } from '@/stores/feature-flag';

function hasClick(obj: any): obj is HTMLElement {
	return typeof obj.click === 'function';
}

export default defineComponent({
	name: 'InterviewAnswerUploadFile',
	components: { ProfileImageEditMode },
	emits: ['click'],

	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		userCv() {
			return this.userProfileStore.obj?.extras?.find(file => file.type === 'cv');
		},
		...mapStores(useUserProfileStore, useFeatureFlagStore)
	},
	methods: {
		openInsidePage() {
			this.$nuxt.$loading.start();
			return import('../../../pages/pwa/picUploadPage.vue')
				.then(async picUploadComponent => {
					await this.$page.push(
						picUploadComponent,
						{},
						{
							pageTitle: 'Bewerbungsfoto hochladen',
							name: 'picUpload',
							on: {
								'end-go-to': () => this.$page.goBack(),
								'pic-uploaded': () => this.skipPicUploadQuestion()
							}
						}
					);
					this.$nuxt.$loading.finish();
				})
				.catch(err => this.$errorHandler.call(this, err));
		},
		async skipPicUploadQuestion() {
			this.$page.goBack();
			const formData = new FormData();
			formData.append('skip', 'true');
			await this.sendAnswer(this.id, formData);
		},
		async handleUpload(e?: any) {
			if (e) {
				e.preventDefault();
			}

			let trackingName;
			if (e.target.dataset?.docname) {
				trackingName = e.target.dataset.docname.includes(':')
					? e.target.dataset.docname.split(':')[1]
					: e.target.dataset.docname;
			}

			if (this.currentUploadStatus) {
				return;
			}
			const { form } = e.target;
			const formData = new FormData(form);
			formData.append('parseCV', this.option.name === 'cv' && !this.parsedOnce ? 'true' : 'false');
			await this.sendAnswer(this.id, formData);
			if (trackingName === 'profilepic') {
				this.$trackUserEvent?.('interview_question_finish', {
					questionType: 'pic',
					answerType: 'file-profilepic'
				});
			}
		},
		async handleSubmit(): Promise<boolean> {
			await this.handleUpload();
			return true;
		},
		clickUpload() {
			if (hasClick(this.$refs.uploadfield)) {
				this.$refs.uploadfield.click();
			}
		}
	},
	props: {
		id: { type: String, required: true },
		msgIndex: { type: Number, default: -1 },
		option: { type: Object, default: () => {} },
		disabled: { type: Boolean },
		sendAnswer: { type: Function, required: true },
		currentVal: { type: [String, Boolean], default: '' },
		currentUploadStatus: { type: [Boolean, Number], default: false },
		parsedOnce: { type: Boolean, default: false }
	}
});
</script>
