<template>
	<div class="range">
		<div class="range-inner">
			<label :for="`range-${id}-${msgIndex}`">
				<span class="val">
					{{ internalValue ? internalValue : '0' }}
				</span>
				{{ option && option.unit }}
			</label>
			<div class="flex">
				<span class="min">
					{{ option && option.min }}
				</span>
				<input
					v-if="option"
					:id="`range-${id}-${msgIndex}`"
					ref="range"
					v-model="internalValue"
					:name="option.name"
					:step="option.steps"
					:min="option.min"
					:max="option.max"
					:data-label="question"
					:disabled="disabled"
					type="range"
				/>
				<span class="max">
					{{ option && option.max }}
				</span>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { IInterviewRangeAnswerOption } from '@hokify/common';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

export default defineComponent({
	name: 'InterviewAnswerRange',
	emits: ['update:modelValue'],
	computed: {
		question() {
			// Don't want to break previous behaviour although "question" is not part of the interface
			return (this.option as any).question ?? this.option.name;
		},
		internalValue: {
			get() {
				return this.modelValue;
			},
			set(value: string) {
				let rangeVal: number;

				const floatValues = value && value.match(/[+-]?\d+(\.\d+)?/g);
				const parsedDefaultValue = this.option.defaultValue && parseFloat(this.option.defaultValue);
				if (floatValues) {
					[rangeVal] = floatValues.map(v => parseFloat(v));
				} else if (parsedDefaultValue && !Number.isNaN(parsedDefaultValue)) {
					rangeVal = Math.round(parsedDefaultValue);
				} else {
					rangeVal = 0;
				}

				this.$emit('update:modelValue', `${rangeVal}`);
			}
		}
	},
	mounted() {
		// load range touch library on mobile phones to improve input mode
		if (this.$isMobile?.any) {
			import('rangetouch').then(RangeTouchFile => {
				const RangeTouch = RangeTouchFile.default || RangeTouchFile;
				// eslint-disable-next-line no-new
				new RangeTouch(this.$refs.range);
			});
		}
	},
	props: {
		id: { type: String, required: true },
		msgIndex: { type: Number, required: true },
		showError: { type: String, default: '' },
		option: { type: Object as PropType<Partial<IInterviewRangeAnswerOption>>, required: true },
		disabled: { type: Boolean, default: false },
		answerOptions: { type: Array, default: () => [] },
		modelValue: { type: String, default: '', required: true }
	}
});
</script>
<style scoped src="../../assets/styles/_inputrange.scss" lang="scss" />
<style scoped lang="scss">
// TODO: @nuxt3 check how to import correctly
.range {
	.range-inner {
		color: $color-text;
		text-align: center;
	}
	input[type='range'] {
		height: 25px;
		width: calc(100% - 100px);
	}
	.min,
	.max {
		font-size: $font-xl;
		font-weight: bold;
		opacity: 0.75;
	}
	.min {
		margin: auto;
	}
	.max {
		margin: auto;
	}
	label {
		margin: $s4 auto $s2;
		font-size: $font-xl;
		display: block;
		text-align: center;
		font-weight: bold;
		span.val {
			display: inline-block;
			text-align: center;
		}
	}
}

input[type='range']::-webkit-slider-runnable-track {
	width: 100%;
	height: 4px;
	cursor: pointer;
	background: #99d3d3;
	border-radius: 5px;
}

input[type='range']:focus::-webkit-slider-runnable-track {
	background: #99d3d3;
}

input[type='range']::-moz-range-track {
	width: 100%;
	height: 4px;
	cursor: pointer;
	background: #99d3d3;
	border-radius: 5px;
}

input[type='range']::-ms-track {
	width: 100%;
	height: 4px;
	cursor: pointer;
	background: transparent;
	border-color: transparent;
	border-width: 16px 0;
	color: transparent;
}

input[type='range']::-ms-fill-lower {
	background: #99d3d3;
	border-radius: 5px;
}

input[type='range']:focus::-ms-fill-lower {
	background: #99d3d3;
}

input[type='range']::-ms-fill-upper {
	background: #99d3d3;
	border-radius: 5px;
}

input[type='range']:focus::-ms-fill-upper {
	background: #99d3d3;
}
</style>
