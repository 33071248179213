<template>
	<div>
		<HokDatePicker
			v-if="option"
			:id="`date-${id}-${msgIndex}`"
			ref="calendar"
			:value="currentDate"
			:disabled="disabled"
			:inline="true"
			:name="option.name"
			:placeholder="option.label"
			:format="customFormatter"
			:minimum-view="option.minimumView"
			:disabled-dates="disabledDates"
			initial-view="year"
			:open-date="openDate ? openDate : undefined"
			@selected="dateSelected"
			@input="currentDate = $event"
		/>
		<div v-if="$refs.calendar" @click="$refs.calendar?.reinit()" v-text="previewDate" />
	</div>
</template>

<script lang="ts">
import HokDatePicker from '@hokify/shared-components-nuxt3/lib/components/HokDatePicker.vue';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

export default defineComponent({
	name: 'InterviewAnswerDate',
	components: {
		HokDatePicker
	},
	data() {
		const openDate = null as Date | null;
		const currentDate = null as any;

		return {
			previewDate: '',
			disabledDates: undefined,
			currentDate,
			openDate,
			submitted: false
		};
	},
	created() {
		if (this.currentVal) {
			this.currentDate = (this.currentVal && new Date(this.currentVal)) || null;
		} else if (this.option && this.option.defaultValue) {
			this.openDate = new Date(this.option.defaultValue);
		}

		this.disabledDates =
			this.option.disableddaterange && typeof this.option.disableddaterange === 'object'
				? this.option.disableddaterange
				: {
						to: this.option.disableddaterange === 'past' ? new Date() : undefined,
						from: this.option.disableddaterange === 'future' ? new Date() : undefined
					};
	},
	methods: {
		dateSelected(date, formattedDate) {
			const now = new Date();
			// if we only select month, the used date should still be in the future, therefore we use current day + 1
			if (this.option.minimumView === 'month' && date.getMonth() === now.getMonth()) {
				date.setDate(now.getDate() + 1);
			}

			this.previewDate = formattedDate;
			if (!this.submitted) {
				this.submitForm(undefined, this.option.name, date);
				this.submitted = true;
			}
		},
		customFormatter(date) {
			return date.toUTCString();
		},
		async handleSubmit(): Promise<boolean> {
			if (this.currentDate) {
				this.submitForm(undefined, this.option.name, this.currentDate);
				return true;
			}

			return false;
		}
	},
	props: {
		id: { type: String, required: true },
		msgIndex: { type: Number, required: true },
		disabled: { type: Boolean },
		option: { type: Object, required: true },
		moduleOptions: { type: Object, default: () => {} },
		submitForm: {
			type: Function as PropType<
				(e?: Event, nameOrPayload?: string | Record<string, string>, value?: any) => unknown
			>,
			default: () => {}
		},
		currentVal: { type: String, required: false, default: () => '' }
	}
});
</script>
