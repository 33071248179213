<template>
	<div
		v-if="
			!user ||
			(user && user.testerGroup !== 'A') ||
			(user && user.testerGroup === 'A' && module !== 'cv') ||
			(user && user.testerGroup === 'A' && module === 'cv' && !hasCv)
		"
	>
		<p v-if="option && option.name === 'send'" class="mt-8 mb-2">Weitere Optionen:</p>
		<HokButton
			v-if="option && option.fct"
			:id="`button-${id}-${msgIndex}-${option.name}`"
			:submit="false"
			:disabled="disabled"
			:name="option.name"
			:value="option.value"
			:outline="option.styling === 'outline'"
			:is-text="option.styling === 'text'"
			color="main"
			class="mt-4"
			@click="callFct(option.fct, false, option)"
		>
			{{ option && option.label }}
		</HokButton>
		<HokButton
			v-else
			:id="`button-${id}-${msgIndex}-${option && option.name}`"
			:disabled="disabled"
			:name="option && option.name"
			:outline="option && option.styling === 'outline'"
			:is-text="option && option.styling === 'text'"
			color="main"
			class="mt-4"
			@click="handleSubmit"
		>
			{{ option && option.label }}
			<HokIcon
				v-if="option && option.name !== 'send' && option.name !== 'skip'"
				name="icon:arrow-long-right"
				color="white"
				:size="4"
				pointer
				class="ml-2"
			/>
		</HokButton>
		<ToolTip
			v-if="option && option.tooltip"
			icon="icon:info"
			color="grey-light"
			:text="option && option.tooltip"
		/>
	</div>
</template>

<script lang="ts">
import ToolTip from '@hokify/shared-components-nuxt3/lib/components/ToolTip.vue';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import type { IAPILoginUser } from '@hokify/common';
import { mapStores } from 'pinia';
import { useUserProfileStore } from '@/stores/user-profile';

export default defineComponent({
	name: 'InterviewAnswerButton',
	components: { ToolTip },
	methods: {
		handleSubmit() {
			this.submitForm(undefined, this.option?.name, true);
		}
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		hasCv(): boolean {
			return !!this.userProfileStore.obj?.extras?.find(file => file.type === 'cv');
		},
		...mapStores(useUserProfileStore)
	},
	props: {
		id: { type: String, required: true },
		msgIndex: { type: Number, default: -1 },
		disabled: { type: Boolean },
		option: { type: Object, default: () => {} },
		callFct: { type: Function, required: true },
		submitForm: {
			type: Function as PropType<
				(e?: Event, nameOrPayload?: string | Record<string, string>, value?: any) => unknown
			>,
			default: () => {}
		},
		module: { type: String, default: () => {} }
	}
});
</script>
