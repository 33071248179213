import { defineStore } from 'pinia';

export interface ITypeformState {
	firstName: string;
	lastName: string;
}

export const useTypeformStore = defineStore('typeform', {
	state: (): ITypeformState => ({
		firstName: '',
		lastName: ''
	}),
	actions: {
		setLastName(lastName) {
			this.lastName = lastName;
		},
		setFirstName(firstName) {
			this.firstName = firstName;
		}
	}
});
